// main imports
import * as React from "react";
import { graphql } from "gatsby";

// plugin imports
import { Trans } from "react-i18next";

// style imports
import "../legal.scss";

// components imports
import Layout from "../../../components/Layout/Layout.js";
import Seo from "../../../components/Seo/Seo.js";

// svg imports

// page
export default function IndexPage() {
	return (
		<Layout>
			<Seo title={"Cookies"} />
			<div className="bodytext">
				<Trans i18nKey={"adviceText"}>
					<h2 className="boxxy">Aviso legal</h2>
					<p>
						AVISO LEGAL LEY DE SERVICIOS DE LA SOCIEDAD DE LA INFORMACIÓN Y
						COMERCIO ELECTRÓNICO
					</p>
					<p>
						<b>1. DATOS IDENTIFICATIVOS</b>: En cumplimiento del deber de
						información recogido en el artículo 10 de la Ley 34/2002, de 11 de
						julio, de Servicios de la Sociedad de la Información y del Comercio
						electrónico, a continuación se reflejan los siguientes datos: la
						empresa titular de www.epicboutiquefitness.com es{" "}
						<b>EI8HT SQUAD S.L.</b>, con CIF número B40615262, y domicilio a
						estos efectos en C/ Almirante Cadarso 15 bajo, 46005 Valencia con
						correo electrónico de contacto: admin@epicboutiquefitness.com
					</p>
					<p>
						<b>2. USUARIOS</b>: El acceso y/o uso de este portal de EI8HT SQUAD
						S.L. atribuye la condición de USUARIO, que acepta, desde dicho
						acceso y/o uso, las Condiciones Generales de uso aquí reflejadas.
						Las citadas condiciones serán de aplicación independientemente de
						las Condiciones Generales de Contratación que en su caso resulten de
						obligado cumplimiento.
					</p>
					<p>
						<b>3. USO DEL PORTAL</b>: www.epicboutiquefitness.com proporciona el
						acceso a multitud de informaciones, servicios, programas o datos (en
						adelante, “los contenidos”) en Internet pertenecientes a EI8HT SQUAD
						S.L. a los que el USUARIO pueda tener acceso. EL USUARIO asume la
						responsabilidad de uso del portal. Dicha responsabilidad se extiende
						al registro que fuese necesario para acceder a determinados
						servicios o contenidos.En dicho registro el USUARIO será responsable
						de aportar información veraz y lícita. Como consecuencia de este
						registro, al USUARIO se le puede proporcionar una contraseña de la
						que será responsable, comprometiéndose a hacer un uso diligente y
						confidencial de la misma. EL USUARIO se compromete a hacer un uso
						adecuado de los contenidos y servicios que <b>EI8HT SQUAD S.L.</b>{" "}
						ofrece a través de su página Web, comprometiéndose a no utilizarlos
						para actividades que puedan ser ilícitas, contrarias al orden
						público, a la defensa nacional o a la salud pública.En todo caso,
						EI8HT SQUAD S.L. se reserva el derecho de modificar unilateralmente,
						en cualquier momento y sin necesidad de previo aviso, la estructura
						y diseño del sitio Web: modificar o eliminar servicios o contenidos,
						así como las condiciones de acceso y/o uso del sitio Web.Del mismo
						modo <b>EI8HT SQUAD S.L.</b> se reserva el derecho a modificar en
						cualquier momento las presentes condiciones de uso, así como
						cualesquiera otras condiciones particulares contenidas en el sitio
						web www.epicboutiquefitness.com
					</p>
					<p>
						<b>4. PROTECCIÓN DE DATOS DE CARÁCTER PERSONAL</b>: n cumplimiento
						de lo establecido en el Reglamento General (UE) 2016/679 de
						Protección de Datos y en la Ley Orgánica 3/2018, de 5 de diciembre,
						de Protección de Datos Personales y garantía de los derechos
						digitales, comunicamos al usuario que los datos personales que nos
						facilite de forma voluntaria , por cualquiera de nuestros medios de
						recogida de información, van a ser incorporados a las Actividades de
						Tratamiento automatizados de datos de carácter personal titularidad
						de <b>EI8HT SQUAD S.L.</b>, con el fin de poderle prestar nuestros
						servicios, así como para mantenerle informado sobre cuestiones
						relativas a la actividad de la empresa y sus servicios. Los campos
						marcados con “*” son de carácter obligatorio, por lo que la no
						cumplimentación de dichos campos impedirá al USUARIO disfrutar de
						algunos de los Servicios e informaciones ofrecidas en la Web. Los
						datos contenidos en ellos serán tratados de forma confidencial.EI8HT
						SQUAD S.L. cumple íntegramente con la legislación vigente en materia
						de protección de datos de carácter personal, y con los compromisos
						de confidencialidad propios de su actividad.<b>EI8HT SQUAD S.L.</b>{" "}
						ha adoptado las medidas técnicas necesarias para mantener el nivel
						de seguridad requerido, según la naturaleza de los datos personales
						tratados y las circunstancias del tratamiento, con el objeto de
						evitar, en la medida de lo posible y siempre según el estado de la
						técnica, su alteración, pérdida, tratamiento o acceso no autorizado.
						Para la realización de la gestión antes mencionada puede ser
						necesario que se cedan los datos a encargados del tratamiento,
						empresas colaboradoras y administraciones públicas (las cuales
						pueden verificar la veracidad de los datos). En caso de que el
						USUARIO facilite datos de carácter personal, referentes a personas
						físicas distintas a sí mismo, el USUARIO deberá, con carácter previo
						a la inclusión, informarles del contenido de la presente clausula,
						de acuerdo con lo establecido en el Reglamento General (UE) 2016/679
						de Protección de Datos y en la Ley Orgánica 3/2018, de 5 de
						diciembre, de Protección de Datos Personales y garantía de los
						derechos digitales.<b>EI8HT SQUAD S.L.</b> como responsable de las
						Actividades de Tratamiento, garantiza el ejercicio de los derechos
						de acceso, rectificación, supresión, limitación, oposición y
						portabilidad de los datos aportados, según lo dispuesto en el
						Reglamento General (UE) 2016/679 de Protección de Datos y en la Ley
						Orgánica 3/2018, de 5 de diciembre, de Protección de Datos
						Personales y garantía de los derechos digitales, el USUARIO podrá
						ejercer en todo momento los derechos, dirigiendo un escrito al
						domicilio social, adjuntando copia del DNI o Pasaporte.
						<br />
						<b>EI8HT SQUAD S.L.</b> garantiza que todas las comunicaciones
						comerciales que dirige a sus clientes cumplen la normativa
						vigente.Por ello, en cumplimiento de la Ley 34/2002 de Servicios de
						la Sociedad de la Información y de Comercio Electrónico y la Ley
						9/2014 General de Telecomunicaciones, se informa al USUARIO que{" "}
						<b>EI8HT SQUAD S.L.</b> podrá enviarle por medios electrónicos
						información acerca de productos y servicios de interés a lo que Ud.
						presta su consentimiento. En cualquier momento podrá revocar esta
						autorización en la dirección admin@epicboutiquefitness.com
					</p>
					<p>
						<b>5. USO DE COOKIES</b>: Se advierte al USUARIO que este sitio Web
						utiliza cookies. Las cookies son pequeños archivos de texto que se
						instalan en el navegador del ordenador del USUARIO para registrar su
						actividad, enviando una identificación anónima que se almacena en el
						mismo, con la finalidad de que la navegación sea más sencilla,
						permitiendo, por ejemplo, el acceso a los USUARIOS que se hayan
						registrado previamente y el acceso a las áreas, servicios,
						promociones o concursos reservados exclusivamente a ellos sin tener
						que registrarse en cada visita. Se pueden utilizar también para
						medir la audiencia, parámetros del tráfico y navegación, tiempo de
						sesión, y/o controlar el progreso y número de entradas. EI8HT SQUAD
						S.L. procurará en todo momento establecer mecanismos adecuados para
						obtener el consentimiento del USUARIO para la instalación de cookies
						que lo requieran. No obstante, lo anterior, deberá tenerse en cuenta
						que, de conformidad con la ley, se entenderá que (i)el USUARIO ha
						dado su consentimiento si modifica la configuración del navegador
						deshabilitando las restricciones que impiden la entrada de cookies y
						(ii)que el referido consentimiento no será preciso para la
						instalación de aquellas cookies que sean estrictamente necesarias
						para la prestación de un servicio expresamente solicitado por el
						USUARIO (mediante registro previo).Puede ocurrir que algunas cookies
						utilizadas en este sitio Web no estén relacionadas con EI8HT SQUAD
						S.L.. Ello se debe a que algunas páginas del sitio Web tienen
						insertado contenido procedente de webs de terceras partes (como por
						ejemplo un video de YouTube).Debido a que el referido contenido
						procede de otra web, <b>EI8HT SQUAD S.L.</b> no controla la
						configuración de dichas cookies. Si usted quiere cambiar sus
						preferencias de configuración de cookies, deberá consultar los
						sitios Web de dichas terceras partes para obtener información.
					</p>
					<p>
						<b>8. PROPIEDAD INTELECTUAL E INDUSTRIAL</b>:{" "}
						<b>EI8HT SQUAD S.L.</b> por sí o como cesionaria, es titular de
						todos los derechos de propiedad intelectual e industrial de su
						página Web, así como de los elementos contenidos en la misma (a
						título enunciativo, imágenes, sonido, audio, video, software o
						textos; marcas o logotipos, combinaciones de colores, estructura y
						diseño..), quedando expresamente prohibidas su reproducción,
						distribución y comunicación pública, incluida la modalidad de puesta
						a disposición de la totalidad o parte de los contenidos de esta
						página Web, en cualquier soporte y por cualquier medio técnico, sin
						la autorización de <b>EI8HT SQUAD S.L.</b>.
						<br />
						El USUARIO se compromete a respetar los derechos de Propiedad
						intelectual e Industrial titularidad de <b>EI8HT SQUAD S.L.</b>. El
						USUARIO deberá abstenerse de suprimir, alterar, eludir o manipular
						cualquier dispositivo de protección o sistema de seguridad que
						estuviera instalado en la página Web.
					</p>
					<p>
						<b>7. EXCLUSIÓN DE GARANTÍAS Y RESPONSABILIDAD</b>:{" "}
						<b>EI8HT SQUAD S.L.</b>
						no se hace responsable, en ningún caso, de los daños y perjuicios de
						cualquier naturaleza que pudieran ocasionar, a título enunciativo:
						errores u omisiones en los contenidos, falta de disponibilidad del
						sitio Web o la trasmisión de virus o programas maliciosos o lesivos
						en los contenidos, a pesar de haber adoptado todas las medidas
						tecnológicas necesarias para evitarlo.Deficiencias del servicio, de
						las redes de comunicación, de los problemas resultantes del mal
						funcionamiento o uso de versiones no optimizadas de cualquier
						navegador, posibles errores de seguridad que se pueden producir ni
						de los posibles daños que se puedan causar en el sistema informático
						del USUARIO, a los ficheros o documentos almacenados en el mismo,
						como consecuencia de la presencia de virus en el ordenador del
						USUARIO utilizado para la conexión a los servicios y contenidos del
						sitio Web, averías telefónicas, interferencias, omisiones o
						desconexiones en el funcionamiento operativo del sistema electrónico
						motivados por causas ajenas a <b>EI8HT SQUAD S.L.</b>; del
						conocimiento que puedan tener terceros no autorizados de la clase,
						condiciones, características y circunstancias de acceso y uso que
						los USUARIOS hacen del sitio Web y de las informaciones y servicios.
						Del uso ilícito, negligente, fraudulento, contrario a las presentes
						Condiciones Generales, a la buena fe, a los usos generales aceptados
						o al orden público, del sitio Web, sus servicios o contenidos, por
						parte de los USUARIOS.
					</p>
					<p>
						<b>8. MODIFICACIONES</b>: <b>EI8HT SQUAD S.L.</b> se reserva el
						derecho a efectuar sin previo aviso las modificaciones que considere
						oportunas en la página web, pudiendo cambiar, suprimir o añadir
						tanto contenidos y servicios que se presten a través de la misma
						como la forma en la que estos aparezcan presentados o localizados en
						su portal.
					</p>
					<p>
						<b>9. ENLACES</b>: En el caso de que en www.epicboutiquefitness.com
						se dispusiesen enlaces o hipervínculos hacia otros sitios de
						internet, <b>EI8HT SQUAD S.L.</b> no ejercerá ningún tipo de control
						sobre dichos sitios y contenido. Bajo ninguna circunstancia, EI8HT
						SQUAD S.L. asumirá responsabilidad alguna por los contenidos de
						algún enlace perteneciente a un sitio web ajeno, ni garantizará la
						disponibilidad técnica, calidad fiabilidad, exactitud, amplitud,
						veracidad, validez y constitucionalidad de cualquier material o
						información contenida en ninguno de dichos hipervínculos u otros
						sitios de internet. Igualmente, la inclusión de estas conexiones
						externas no implicará ningún tipo de asociación, fusión o
						participación con las entidades conectadas.
					</p>
					<p>
						<b>10. DERECHO DE EXCLUSIÓN</b>: <b>EI8HT SQUAD S.L.</b> se reserva
						el derecho de bloquear el acceso de un USUARIO ante cualquier
						indicio de utilización fraudulenta de los servicios ofrecidos sin
						necesidad de preaviso, a instancia propia o de un tercero, a
						aquellos usuarios que incumplan las presentes condiciones Generales
						de Uso.
					</p>
					<p>
						<b>11. GENERALIDADES</b>: <b>EI8HT SQUAD S.L.</b> perseguirá el
						incumplimiento de las presentes condiciones, así como cualquier
						utilización indebida de su portal ejerciendo todas las acciones
						civiles y penales que le pueden corresponder en derecho.
					</p>
					<p>
						<b>12. MODIFICACIÓN DE LAS PRESENTES CONDICIONES Y DURACIÓN</b>:
						<b>EI8HT SQUAD S.L.</b> podrá modificar en cualquier momento las
						condiciones aquí determinadas, siendo debidamente publicadas como
						aquí aparecen. La vigencia de las citadas condiciones irá en función
						de su exposición y estarán vigentes hasta que sean modificadas por
						otras debidamente publicadas.
					</p>
					<p>
						<b>13. LEGISLACIÓN APLICABLE Y JURISDICCIÓN</b>: La relación entre{" "}
						<b>EI8HT SQUAD S.L.</b> y el USUARIO se regirá por la normativa
						española vigente y cualquier controversia se someterá a los Juzgados
						y Tribunales de Valencia.
					</p>
				</Trans>
			</div>
		</Layout>
	);
}

// graphql query
export const query = graphql`
	query ($language: String!) {
		locales: allLocale(filter: { language: { eq: $language } }) {
			edges {
				node {
					ns
					data
					language
				}
			}
		}
	}
`;
